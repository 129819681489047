import request from '@manage/utils/request'

export function handleSaveConfigWx(data) { //保存支付配置
	return request({
		url: 'admin/weixin/saveConfigWx',
		method: 'post',
		data
	})
}


export function handleQueryWxConfig() { //获取微信配置信息
	return request({
		url: 'admin/weixin/queryWxConfig',
		method: 'post'
	})
}
