import {
	Message
} from 'element-ui'

import {
	handleSaveConfigWx,
	handleQueryWxConfig
} from '@manage/api/bm/payconfig';
export default {
	name: 'payconfig',
	components: {},
	data() {
		return {
			wxConfig: {payBackcallType:'1'}
		}
	},
	watch: {

	},
	created() {
		this.queryWxConfig();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		selectType(value){
			this.wxConfig.payBackcallType=value;
			this.saveConfigWx(this.wxConfig);
		},
		queryWxConfig() {//查询
			handleQueryWxConfig().then(res => {
				if (res.status == 200) {
					this.wxConfig = res.data;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		saveConfigWx(data) {//保存
			handleSaveConfigWx(data).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		}
	}
}
